import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import { appType } from './appStore';
import axios from 'axios';
import queryString from 'query-string';
import { registrationType, User } from '../type';

type claimType = {Key:string, Value:string|string[]|number|boolean};
type sessionType = {Key?:string, Type?:string, Issued?:Date, Expires?:Date};
type resetPasswordType = { password?: string, oldPassword?: string, reset?: boolean, userid?: number };

export default class AuthStore
{
    @observable busy:boolean = false;
    @observable authenticated:boolean = false;
    @observable session:sessionType = {};
    @observable user:User = {};
    @observable claims:claimType[] = [];
    @observable identityTypes: string[] = [];
    @observable errors?:string|(string|null)[] = undefined;

    private route:string;
    public readonly types:string[];
    private sessionValid:Date|undefined;

    private static readonly storageKey = "Auth";

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("AuthService");
        this.types = storeHelpers.GetEnvVar('authorizationTypes') as string[];
        const stored = AuthStore.getSaved();
        const sessionEnv = storeHelpers.GetEnvVar('sessionValid');
        const parsed = (typeof(sessionEnv) === 'string' || typeof(sessionEnv) === 'number') ? new Date(sessionEnv) : undefined;
        this.sessionValid = (parsed && !Number.isNaN(parsed)) ? parsed : undefined;
        if(stored)
        {
            const {session, user} = stored;
            if(session)
            {
                this.session = session;
                this.user = user;
                this.claims = AuthStore.parseClaims(user.claims ?? []);
                this.authenticated = true;
            }
        }
        if (this.authenticated && (this.expired || this.invalid)) {
            this.refresh(this.session?.Key)
                .then((refreshed) => {
                    if (!refreshed) {
                        this.session = {};
                        this.user = {};
                        this.authenticated = false;
                        this.claims = [];
                        AuthStore.clearSaved();
                    }
                });
        }
        if(!this.authenticated) {
            const { search } = window.location;
            const { session: requestSession, ticket, errors, ...rest } = queryString.parse(search);
            if(requestSession || ticket || errors) {
                window.history.replaceState({}, document.title, `${window.location.origin}${window.location.pathname}${rest ? `?${queryString.stringify(rest)}`:''}${window.location.hash}`);
            }
            if(errors) {
                this.errors = errors;
            } else {
                const sessionKey = typeof(requestSession === 'string') ? requestSession as string : undefined;
                const ticketKey = typeof(ticket === 'string') ? ticket as string : undefined;
                this.refresh(sessionKey, ticketKey);
            }
        }
    }

    private static parseClaims(claims:{key:string, value:string}[] ): {Key:string, Value:any}[]
    {
        const mapped: ({Key:string, Value:any}|null)[] = claims.map(claim => {
            const parts = claim.key.split('.');
            if(parts && parts.length === 3)
            {
                switch(parts[0].toLowerCase())
                {
                    case 'boolean':
                        return {Key: claim.key, Value: claim.value === 'true'};
                    case 'number':
                        return {Key:claim.key, Value: parseInt(claim.value?.toString() ?? "0", 10)};
                    case 'list':
                        return {Key:claim.key, Value: claim.value?.split(',')};
                    default:
                        return {Key:claim.key, Value: claim.value};
                }
            }
            return null;
        });
        return mapped.filter(c => c !== null) as {Key:string, Value:any}[];
    }

    public static GetConfig(headers:{[key:string]:string} = {}) {
        const stored = AuthStore.getSaved();
        if(!stored) return;
        const {session} = stored;
        if(!session || !session.Key) return;
        const expiresAt = session.Expires ? new Date(session.Expires) : null;
        if(expiresAt && expiresAt.valueOf() < Date.now()) return;
        return { headers: {"Authorization": `Bearer ${session.Key}`, ...headers}};
    }

    @computed get extantSession(): boolean { return !!this.session; }

    @computed get expired(): boolean { return this.session?.Expires ? new Date(this.session.Expires).valueOf() < Date.now() : false; }

    @computed get invalid(): boolean { return this.sessionValid && this.session?.Issued ? new Date(this.sessionValid).valueOf() > new Date(this.session.Issued).valueOf() : false; }

    @action refresh = async (session?: string, ticket?: string): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
        const sessionId = session ?? this.session?.Key;
        if(!sessionId && !ticket) resolve(false);
        const query = !!sessionId ? queryString.stringify({session: sessionId}) : (ticket ? queryString.stringify({ticket}) : '');
            axios.get(`${this.route}api/v1/auth/refresh${query ? `?${query}` : ''}`, AuthStore.GetConfig())
            .then(({data}) => {
                const { user, session: savedSession } = data ?? {};
                if(user && savedSession) {
                    runInAction(() => {
                        this.session = savedSession;
                        this.user = user;
                        this.claims = AuthStore.parseClaims(user.claims ?? []);
                        this.authenticated = true;
                        const persist = !savedSession.Expires;
                        AuthStore.setSaved({session: savedSession, user}, persist);
                        resolve(true);
                    });
                } else {
                    resolve(false);
                }
            })
            .catch((err) => {
                console.log(`refresh auth failed: ${err}`);
                resolve(false);
            });
        });
    }

    @action fetchIdentities = async (): Promise<string[]> => {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/auth/types`, AuthStore.GetConfig())
            .then(response => {
                const { data = [] } = response;
                runInAction(() => {
                    this.identityTypes = data;
                });
                resolve(data);
            }).catch( err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        });
    }

    @action check = ():boolean =>
    {
        if(!this.authenticated) { return false; }
        const { Expires } = this.session ?? {};
        if(!Expires) { return true; }
        if(Expires < new Date()) {
            // clear out our session, maybe prompt for re-login
            AuthStore.clearSaved();
            this.authenticated = false;
            return false;
        }
        return true;
    }

    @action Authenticate = async (type: string, payload: { username?: string, email?: string, passkey?: string, token?:string }):Promise<{user:User, session:sessionType, apps:appType[]}> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.post(`${this.route}api/v1/auth/authenticate/${type}`, payload)
                .then((response) => {
                    const {session, user} = response.data;
                    runInAction(() => {
                        this.session = session;
                        this.user = user;
                        this.authenticated = true;
                        this.claims = AuthStore.parseClaims(user.claims ?? []);
                    });
                    const persist = !session.Expires;
                    AuthStore.setSaved({session, user}, persist);
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.response);
                })
                .finally(() => {
                    runInAction(() => { this.busy = false; });
                });
        });
    }

    @action SignIn = (user:User & {claims:{ key:string, value:string }[]}, session:sessionType, apps:appType[]) =>
    {
        this.session = session;
        this.user = user;
        this.authenticated = true;
        this.claims = AuthStore.parseClaims(user.claims ?? []);
        const persist = !session.Expires;
        AuthStore.setSaved({session, user}, persist);
    }

    @action SignOut = async ():Promise<boolean> => {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.post(`${this.route}api/v1/auth/signout`, AuthStore.GetConfig())
                .then(() => {
                    runInAction(() => {
                        this.session = {};
                        this.user = {};
                        this.authenticated = false;
                        this.claims = [];
                        AuthStore.clearSaved();
                    });
                    resolve(true);
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                });
        });
    }

    @action Register = async (params: registrationType, type: string, app?:string): Promise<{user:User, session:sessionType}> => {
        this.busy = true;
        return new Promise<{user:User, session:sessionType}>((resolve, reject) => {
            const query = app ? `/${app}`:'';
            axios.put(`${this.route}api/v1/auth/register/${type}${query}`, params)
                .then((response) => {
                    const {session, user} = response.data;
                    runInAction(() => {
                        this.session = session;
                        this.user = user;
                        this.authenticated = true;
                        this.claims = AuthStore.parseClaims(user.claims ?? []);
                    });
                    const persist = !session.Expires;
                    AuthStore.setSaved({session, user}, persist);
                    resolve(response.data);
                })
                .catch((err) => {
                    const { status, data } = err?.response ?? {};
                    switch(status) {
                        case 409:
                            const { types } = data;
                            const supportedTypes = types.filter((t:string) => this.types.includes(t));
                            reject({ message: 'An account already exists for this email', types: supportedTypes });
                            break;
                        case 410:
                            reject({ message: "An account already exists for this email" });
                            break;
                        default:
                            reject({ message: "Unknown registration error" });
                            break;
                    }
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    });
                });
        });
    }

    @action EditProfile = async (profile: User): Promise<User> => {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.patch(`${this.route}api/v1/user`, profile, AuthStore.GetConfig())
            .then( ({ data:savedUser }:{ data:User } ) => {
                runInAction(() => {
                    this.user = savedUser;
                    AuthStore.setSaved({session: this.session, user: savedUser}, !this.session.Expires);
                });
                resolve(savedUser);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action ChangePassword = async(payload: resetPasswordType): Promise<string|undefined> => {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const { reset = false, password, oldPassword, userid } = payload;
            const validReset = !!reset && !!userid && this.hasPermission("BOOLEAN.AuthService.CanAccess", true);
            const validChange = !reset && !!password && !!oldPassword;
            if(!validReset && !validChange) {
                reject();
            } else {
                axios.post(`${this.route}api/v1/auth/password`, payload, AuthStore.GetConfig())
                .then( ({ data: { password: updatedPassword } = {} }:{ data?: { password?: string } } ) => {
                    resolve(validReset ? updatedPassword : undefined);
                })
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    runInAction(() => { this.busy = false; });
                });
            }
        })
    }

    private static getSaved() {
        let saved = localStorage.getItem(AuthStore.storageKey);
        if(saved) {
            return JSON.parse(saved);
        }
        saved = sessionStorage.getItem(AuthStore.storageKey);
        if(saved) {
            return JSON.parse(saved);
        }
        return;
    }

    private static setSaved(value: any, persist: boolean = false) {
        if(persist) {
            localStorage.setItem(AuthStore.storageKey, JSON.stringify(value));
        } else {
            sessionStorage.setItem(AuthStore.storageKey, JSON.stringify(value));
        }
    }

    private static clearSaved() {
        sessionStorage.removeItem(AuthStore.storageKey);
        localStorage.removeItem(AuthStore.storageKey);
    }

    public hasPermission(permission:string, value:any = true, condition?:(user?:{id?:number}, claims?:{Key:string, Value:string|string[]|boolean|number}[])=>boolean):boolean
    {
        const claim = this.claims.find(c => c.Key === permission);
        return (claim?.Value === value) && (condition ? condition(this.user, this.claims) : true);
    }
}