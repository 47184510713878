import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import { storeHelpers } from '@vaettyr/boltcave-client-core';
import axios from 'axios';
import AuthStore from './authStore';

export type Claim = {key:string, value:string|number|boolean};
export type PermissionSet = Claim | Claim[] | (Claim | Claim[])[];
export type appType = {Display?:string, Service?:string, Url?:string, SortOrder?:number, Icon?:string, Claims?:PermissionSet, Enabled?:boolean, id?:number};

export default class AppStore
{
    @observable busy:boolean = false;
    @observable initialized = false;
    @observable apps:appType[] = [];

    private static readonly storageKey = "App";
    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("AuthService");
        const stored = sessionStorage.getItem(AppStore.storageKey);
        if(stored)
        {
            const apps = JSON.parse(stored);
            this.apps = apps;
            this.initialized = true;
        }
    }

    public static parseApps(apps?:appType[]):{Display:string, Url:string, Icon?:string}[] {
        return (apps ?? [])
            .map(app => {
                const url = app.Url ?? storeHelpers.GetEndpoint(app.Service as string);
                const display = app.Display ?? url;
                return {Display:display, Url: url, Icon:app.Icon}
            })
            .filter(app => app.Url !== "/");
    }

    @computed get displayApps():{Display:string, Url:string, Icon?:string}[] {
        return AppStore.parseApps(this.apps);
    }

    @action Initialize = () => {
        if(this.initialized || this.busy) { return; }
        this.GetApps();
    }

    @action SetApps = (apps:appType[]) => {
        this.apps = apps;
        this.initialized = true;
    }

    @action GetApps = async():Promise<{Display:string, Url:string}[]> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/app`, AuthStore.GetConfig())
                .then(response => {
                    const {data:apps} = response;
                    runInAction(() => {
                        this.apps = apps;
                    });
                    resolve(apps);
                })
                .catch(err => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                        this.initialized = true;
                    });
                });
        });
    }

    @action SaveApp = async(app:appType):Promise<appType|undefined> =>
    {
        this.busy = true;
        const index = this.apps.findIndex(a => a.id === app.id);
        return new Promise((resolve, reject) => {
            const create = index < 0;
            const request = create ?
                axios.put(`${this.route}api/v1/app`, app, AuthStore.GetConfig()):
                axios.post(`${this.route}api/v1/app`, app, AuthStore.GetConfig());

                request.then(response => {
                    const { data:updated } = response;
                    if(create) {
                        const newApps = [...this.apps, updated];
                        runInAction(() => {
                            this.apps = newApps.sort((a,b) => (a.SortOrder ?? 0) - (b.SortOrder ?? 0));
                        });
                    } else {
                        const newApps = [...this.apps.slice(0,index), updated, ...this.apps.slice(index + 1)];
                        runInAction(() => {
                            this.apps = newApps.sort((a,b) => (a.SortOrder ?? 0) - (b.SortOrder ?? 0));
                        });
                    }
                    resolve(updated);
                })
                .catch(err => {
                    reject(err.response);
                })
                .finally(()=> {
                    runInAction(() => {
                        this.busy = false;
                    });
                });
        });
    }

    @action DeleteApp = async(app:appType):Promise<true> => {
        this.busy = true;
        const index = this.apps.findIndex(a => a.id === app.id);
        return new Promise((resolve, reject) => {
            axios.delete(`${this.route}api/v1/app/${app.id}`, AuthStore.GetConfig())
                .then(() => {
                    runInAction(() => {
                        this.apps = [...this.apps.slice(0, index), ...this.apps.slice(index + 1)];
                    });
                    resolve(true);
                })
                .catch((err) => {
                    reject(err.response);
                })
                .finally(() => {
                    runInAction(() => {
                        this.busy = false;
                    })
                });
        });
    }
}