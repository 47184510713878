import { inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import roleStore from '../stores/roleStore';
import {modalService, toastService} from '@vaettyr/boltcave-client-core';
import {Role as RoleType, App} from '../type';
import EditRole from './EditRole';
import { HandleSecureError } from '../helpers/authHelpers';

type RolesProps = {
    rolestore?:roleStore,
    modalservice?:modalService,
    toastservice?:toastService
}


export default inject('rolestore', 'modalservice', 'toastservice' ) (
    observer(
        function Roles({rolestore, modalservice, toastservice}:RolesProps) {

            const { roles = [], byApp = {}, permissions = [], busy = false } = rolestore ?? {};

            useEffect(() => {
                rolestore?.fetch();
                rolestore?.fetchPermissions();
            }, []);

            function saveRole(values:{[key:string]:any}, original?: RoleType) {
                const claims = Object.keys(values)
                    .filter( key => (/^[^\-]+\-[^\-]+\-[^\-]+$/g).test(key))
                    .map( key => {
                        const name = key.replaceAll('-', '.');
                        const id = permissions.find(p => `${p.Type}.${p.Service}.${p._key}` === name )?.id;
                        const value = (values as {[index:string]:any})[key];
                        return {permissionid:id, key:name, value: Array.isArray(value) ? value.join(',') : value};
                    });
                const role = { id: values.id, name: values.name, default: values.default, app: values.app, claims };
                rolestore?.saveRole(role)
                    .then( () => {
                        modalservice?.hide('role');
                    })
                    .catch( err => {
                        HandleSecureError(err, toastservice);
                    });
            }

            function closeModal() {
                modalservice?.hide('role');
            }

            function showRole( role?:RoleType ) {
                return () => {
                    if(modalservice?.isActive) { return; }
                    if(!role) {
                        modalservice?.show({
                            body: <EditRole role={role} permissions={byApp} onSave={saveRole} onCancel={closeModal}/>,
                            key:"role",
                            options: { className: "role" }
                        });
                    } else {
                        rolestore?.loadRole(role)
                        .then(loaded => {
                            modalservice?.show({
                                body: <EditRole role={loaded} permissions={byApp} onSave={saveRole} onCancel={closeModal}/>,
                                key:"role",
                                options: { className: "role" }
                            });
                        })
                        .catch(err => {
                            HandleSecureError(err, toastservice);
                        });
                    }
                }
            }

            function deleteRole(role:RoleType) {
                return ( e: React.MouseEvent ) => {
                    e.preventDefault();
                    e.stopPropagation();
                    rolestore?.deleteRole(role);
                }
            }

            return (
                <div className="roles-table">
                    { busy && <progress className="progress is-small is-primary" max="100">15%</progress>}
                    {roles.map( (role:RoleType) => {
                        return (
                            <div className="role-container" onClick={showRole(role)} key={`role-${role.id}`}>
                                <i className="fas fa-solid fa-user-tag"/>
                                <strong>{role.name}</strong>
                                <small>{(role.app as App)?.Display}</small>
                                <button className="delete" onClick={deleteRole(role)}></button>
                            </div>
                        );
                    } ) }
                    <button className={`button is-primary`} onClick={showRole()}>New Role</button>
                </div>
            )
        }
    )
);