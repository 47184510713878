import { Permission } from "../type";
import {useField} from 'formik';
import { Checkbox, NumberInput, Select, TagsInput, TextInput } from "@vaettyr/boltcave-client-core";

type PermissionSetProps = {
    permissions?:Permission[],
    name:string,
    label?:string
}

type ClaimSelectorProps = {
    item: any,
    index:number,
    name:string,
    addClaim:(() => void),
    removeClaim:((index:number)=>(()=>void)),
    permissions:Permission[]
}

type ClaimValueProps = {
    type?:string,
    name:string,
    options?:string[]
}

function ClaimValue({type, name, options = []}:ClaimValueProps) {
    switch(type) {
        case "ENUM":
            return <Select name={name} label="Value" options={["", ...options]} required/>;
        case "BOOLEAN":
            return <Checkbox name={name} label="Value"/>;
        case "STRING":
            return <TextInput name={name} label="Value" required/>;
        case "NUMBER":
            return <NumberInput name={name} label="Value" required/>;
        case "LIST":
            return options.length ?
            <Select name={name} options={["", ...options]} label="Values" multiple required/> :
            <TagsInput name={name} label="Values" required/>;
        default:
            return <span></span>;
    }
}

function ClaimSelector({item, name, index, permissions, addClaim, removeClaim}:ClaimSelectorProps) {
    const display = Array.isArray(item) ? item : [item ?? {}];

    const { services, entries } = permissions.reduce((set, per) => {
        if(!set.services.includes(per.Service)) {
            set.services.push(per.Service);
            set.entries = {...set.entries, [per.Service]: [{key:per._key, type:per.Type}] };
        } else {
            set.entries[per.Service].push({key:per._key, type:per.Type});
        }
        return set;
    }, { services:[""] as string[], entries:{} as {[key:string]: { key:string, type:string }[] } } );

    return (
        <div>
            {display.map((entry, i) => {
                const options = entry.Service ? entries[entry.Service].map(e => e.key) : [];
                const permission = entry.Key && entry.Service ? permissions.find(p => p.Service === entry.Service && p._key === entry.Key) : undefined;
                const onDelete = removeClaim(i);
                const canAnd = (i === display.length - 1) && entry.Value !== undefined;
                return (
                    <div key={`claim-permission-${index}-${i}`} className="permission-entry">
                        <Select label="Service" name={`${name}.${index}.${i}.Service`} options={services} required/>
                        <Select label="Key" name={`${name}.${index}.${i}.Key`} options={["", ...options]} required disabled={!entry.Service}/>
                        <ClaimValue name={`${name}.${index}.${i}.Value`} type={permission?.Type} options={permission?._values}/>
                        <div className="field inclusive">
                            <button className="delete" onClick={onDelete}></button>
                            {i < display.length -1 && <p>and</p>}
                            {canAnd && (
                                <button type="button" className="button is-primary" onClick={addClaim}>and</button>
                            )}
                        </div>
                    </div>
                );
            })}

        </div>
    );
}

export default function PermissionSet({permissions = [], name, label}: PermissionSetProps) {

    const [_field, meta, helpers] = useField({name});
    const { value } = meta;

    const formatted = Array.isArray(value) ? value : (value ? [value] : []);

    const addRow = () => {
        helpers.setValue([...formatted, [{}]], true);
    }

    const addClaim = (index:number) => {
        return () => {
            const entry = Array.isArray(formatted[index]) ? formatted[index] : [formatted[index]];
            helpers.setValue([...formatted.slice(0, index), [...entry, {}], ...formatted.slice(index + 1)], true)
        }
    }

    const removeClaim = (index:number) => {
        return (i:number) => {
            return () => {
                const group = [...formatted[index].slice(0,i), ...formatted[index].slice(i + 1)];
                const claims = group.length ?
                    [...formatted.slice(0, index), group, ...formatted.slice(index + 1)] :
                    [...formatted.slice(0, index), ...formatted.slice(index + 1)];
                helpers.setValue(claims, true);
            }
        }
    }

    return (
        <div className={`permission-set`}>
            { label && <label className="label">{label}</label> }
            {formatted.map((item, index) => {
                return (
                    <div key={`permission-set-${index}`} className="permission-group">
                        <ClaimSelector
                            item={item}
                            permissions={permissions}
                            index={index}
                            name={name}
                            addClaim={addClaim(index)}
                            removeClaim={removeClaim(index)}
                        />
                        <div className="field exclusive">
                            {index < formatted.length - 1 && (<p>or</p>)}
                            {index === formatted.length - 1 && <button type="button" className="button is-primary" onClick={addRow}>or</button> }
                        </div>
                    </div>
                );
            })}
            {formatted.length === 0 && (<button type="button" className="button is-primary" onClick={addRow}>Add Claim</button>)}
        </div>
    );
}