import { useEffect, useState } from 'react';
import { storeHelpers } from '@vaettyr/boltcave-client-core';

type googleLoginArgs = {
  callback?: ((arg:any) => Promise<void>),
  oneTap?: boolean,
  enabled?: boolean
}

const googleUrl = "https://accounts.google.com/gsi/client";

export const initializeGoogle = () => {
  const { accounts } = (window as any).google ?? {};
  accounts?.id.renderButton(
      document.getElementById("google-login"),
      { theme: "outline", size: "large" }  // customization attributes
  );
}

export default ({ callback, oneTap, enabled }: googleLoginArgs): boolean => {

  const [isReady, setIsReady] = useState<boolean>(false);

  const gsid = storeHelpers.GetEnvVar("gsid");
  const { hostname } = window.location;
  const useHostName = !(['localhost', '127.0.0.1']).includes(hostname);

  useEffect(() => {
    const scripts:{ element:HTMLScriptElement, listener:()=>void }[] = [];
    // check to see if this script is already loaded
    const extant = document.querySelectorAll(`script[src="${googleUrl}"]`);
    if(!enabled) {
      setIsReady(true);
    } else if(!gsid) {
      console.log("no gsid found. Google Login not enabled");
    } else if(extant && extant.length > 0) {
      setIsReady(true);
      initializeGoogle();
    } else {
      const script = document.createElement('script');
      script.src = googleUrl;
      script.async = true;
      script.defer = true;
      const listener = () => {
          const { accounts } = (window as any).google;
          accounts.id.initialize({
              client_id: gsid,
              state_cookie_domain: useHostName ? hostname : undefined,
              callback,
              auto_select: false
          });
          if(oneTap) {
              accounts.id.prompt();
          }
          setIsReady(true);
          initializeGoogle();
      }
      script.addEventListener('load', listener);
      document.head.appendChild(script);
      scripts.push({ element:script, listener });
    }

    return () => {
        for(const {element:item, listener} of scripts) {
            item.removeEventListener('load', listener);
            document.head.removeChild(item);
        }
        const { accounts } = (window as any).google ?? {};
        accounts?.id.cancel();
    }
  }, []);

  return isReady;
}