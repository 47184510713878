import {inject, observer} from 'mobx-react';
import { ReactNode } from "react";
import { Claim } from '../type';
import authStore from '../stores/authStore';

type SecuredProps = {
    authstore?:authStore,
    claims:Claim|Claim[]|(Claim|Claim[])[]
    children:ReactNode
}

function doesHavePermission(claims: Claim|Claim[]|(Claim|Claim[])[], authstore?:authStore) {
    return Array.isArray(claims) ?
    (
        claims.some( claim => {
            return Array.isArray(claim) ?
                claim.every( sub => authstore?.hasPermission(sub.key, sub.value, sub.condition)) :
                authstore?.hasPermission(claim.key, claim.value, claim.condition);
        } )
    ) : authstore?.hasPermission(claims.key, claims.value, claims.condition);
}

export default inject('authstore')(observer(
    function Secured({children, claims, authstore}:SecuredProps) {

        const hasPermission = doesHavePermission(claims, authstore);
        if(!hasPermission) return null;
        return (
            <>
                {children}
            </>
        );
    }
));