import { ChangeEvent, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { inject, observer } from 'mobx-react';
import userStore from '../stores/userStore';
import User from './User';
import { User as UserType } from '../type';
import roleStore from '../stores/roleStore';
import AuthStore from '../stores/authStore';
import { modalService, toastService, useTimeout, TextInput, Select } from '@vaettyr/boltcave-client-core';
import { HandleSecureError } from '../helpers/authHelpers';
import EditUser from './EditUser';
import APIUser from './APIUser';

type UsersProps = {
    authstore?: AuthStore,
    userstore?: userStore,
    rolestore?:roleStore,
    modalservice?: modalService,
    toastservice?: toastService
}

const delay = 500; // second and a half
const pageSize = 10;

export default inject('authstore', 'userstore', 'rolestore', 'modalservice', 'toastservice') ( observer(
    (props:UsersProps) => {
        const { authstore, userstore, rolestore, modalservice, toastservice } = props;
        const { users = [], busy, canPage } = userstore ?? {};
        const { roles = [] } = rolestore ?? {};
        const { identityTypes = [] } = authstore ?? {};

        const [searchParams, setSearchParams] = useState<{userName: string, role: number, identity: string}>({userName: "", role: 0, identity: ''});
        const setWait = useTimeout(delay);

        useEffect(() => {
            userstore?.fetch({ paging: { size: pageSize } });
            rolestore?.fetchPermissions();
            rolestore?.fetch();
            authstore?.fetchIdentities();
        }, []);

        const page = () => {
            userstore?.page();
        }

        const showModal = (user: UserType) => {
            if( !authstore?.hasPermission('BOOLEAN.UserService.CanEditUsers')) { return; }
            if( modalservice?.isActive ) { return; }
            userstore?.loadUser(user)
                .then(loaded => {
                    modalservice?.show({
                        body: <EditUser user={loaded}/>,
                        options: { className: 'user-details' },
                        key: "user"
                    });
                })
                .catch(err => HandleSecureError(err, toastservice));
        }

        const createAPIUser = () => {
            modalservice?.show({
                body: <APIUser />,
                options: { className: 'api-user' },
                key: 'api-user'
            });
        }

        const initialValues = {
            userName: '',
            role: 0,
            identity: ''
        }

        const handleUpdate = (values: {userName: string, role: number, identity: string}) => {
            const { userName, role, identity } = values;
            const shouldSearch = (userName.length !== searchParams.userName.length) || role !== searchParams.role || identity !== searchParams.identity;
            if(shouldSearch && !busy) {
                const searchPayload = { searchText: userName.length > 0 ? userName : undefined, role: role > 0 ? role : undefined, identity: identity.length > 0 ? identity : undefined };
                setWait(() => {
                    userstore?.fetch(searchPayload);
                });
            }
            setSearchParams(values);
        }

        return (
            <div className="users-page">
                <Formik initialValues={initialValues} onSubmit={handleUpdate}>
                    {({ values }) => (
                    <>
                        <Form className="user-search-form">
                            <TextInput name="userName" placeholder='User Search' onChange={(value) => handleUpdate({ ...values, userName: value ?? "" })} />
                            <Select name="role" options={['', ...roles.map((role) => ({ label: role.name, value: role.id}))]} onChange={(value) => handleUpdate({ ...values, role: (value ?? 0) as number})} />
                            <Select name="identity" options={['', ...identityTypes]} onChange={(value) => handleUpdate({ ...values, identity: (value ?? "") as string })}/>
                        </Form>
                        <button className="button is-primary" type="button" onClick={createAPIUser}>Add API User</button>
                    </>
                    )}
                </Formik>
                {/* button to create API user */}
                <div className="users-table">
                    {["", "Name", "Email", "Role", "Types",""].map((p, i) => (<div className="header-entry" key={`user-table-header-${i}`}>{p}</div>))}
                    { users.map( user => (
                        <User user={user} key={user.id} editable onClick={showModal} />
                    ) ) }
                    { canPage && <button className="button" role="button" onClick={page}>Load More</button>}
                </div>
            </div>
        )
    }
));