import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {inject, observer} from 'mobx-react';
import SecureApp from './SecureApp';
import authStore from './stores/authStore';
import Login from './components/Login';
import Authenticator from './components/Authenticator';
import { storeHelpers, toastService, toastStyle } from '@vaettyr/boltcave-client-core';
import AppList from './components/AppList';
import { NavLink, Route, Routes, Navigate } from "react-router-dom";
import Users from './admin/Users';
import Roles from './admin/Roles';
import Apps from './admin/Apps';
import './style/index.scss';
import './boltcave.ico';
import Secured from './components/Secured';

type AdminProps = {
    authstore?:authStore,
    toastservice?: toastService
    redirect?:string
}

const Admin = inject('authstore', 'toastservice')(observer(
    function AdminApp({ authstore, redirect, toastservice }:AdminProps) {

        const [isActive, setActive] = useState<boolean>(false);
        const toggle = () => { setActive(!isActive)};
        const active = isActive ? " is-active" : "";

        // if we're not authenticated yet, give them a chance to log in
        const {authenticated = false, errors = []} = authstore ?? {};
        useEffect(() => {
            if(errors?.length > 0) {
                toastservice?.show({
                    message: Array.isArray(errors) ? errors.join(',') : errors,
                    dismissable:true,
                    lifespan: 5,
                    style: toastStyle.danger
                })
            }
        }, [errors?.length]);
        if(!authenticated) {
            return (
                <Login isModal={false} message={<p>You must be logged in to access the Admin Portal</p>}/>
            );
        }

        // if we're logged in and don't have access, bounce them
        const hasAccess = authstore?.hasPermission("BOOLEAN.AuthService.CanAccess", true);
        if(!hasAccess) {
            if(!!redirect && redirect !== "/") {
                window.history.pushState(null, '', redirect);
                window.location.reload();
                return null;
            } else {
                authstore?.SignOut();
                if(!toastservice?.isActive) {
                    toastservice?.show({
                        message: "You do not have permission to access this site",
                        style: toastStyle.danger,
                        dismissable:true,
                        lifespan: -1
                    });
                }
                return null;
            }
        }

        // otherwise, return the admin application
        return (
            <div>
                <nav className="navbar" role="navigation" aria-label="main-navigation">
                    <div className="navbar-brand">
                        <div className="navbar-item">
                            <AppList fill="#FFF"/>
                            <p className="title">{storeHelpers.GetEnvVar("title") as string ?? "The Admincave"}</p>
                        </div>
                        <a role="button" className={`navbar-burger${active}`} aria-label="menu" aria-expanded="false" onClick={toggle}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>

                    <div className="navbar-menu">
                        <div className="navbar-start">
                            <Secured claims={{key:"BOOLEAN.AppService.CanEditApps", value:true}}><NavLink className={`navbar-item`} to={"/apps"}>Apps</NavLink></Secured>
                            <Secured claims={{key:"BOOLEAN.RoleService.CanAccess", value:true}}><NavLink className={`navbar-item`} to={"/roles"}>Roles</NavLink></Secured>
                            <Secured claims={{key:"BOOLEAN.UserService.CanAccess", value:true}}><NavLink className={`navbar-item`} to={"/users"}>Users</NavLink></Secured>
                        </div>
                    </div>

                    <div className="navbar-end">
                        <Authenticator/>
                    </div>
                </nav>
                <Routes>
                    <Route path="/apps" element={<Apps/>}/>
                    <Route path="/users" element={<Users/>}/>
                    <Route path="/roles" element={<Roles/>}/>
                    <Route path="*" element={<Navigate to="/roles" replace/>}/>
                </Routes>
            </div>
        );
    }
))


ReactDOM.render(
  <React.StrictMode>
      <SecureApp>
        <Admin key="admin" redirect={storeHelpers.GetEndpoint("Main")}/>
      </SecureApp>
  </React.StrictMode>,
  document.getElementById('root')
);