import AuthStore from "../stores/authStore";
import { inject, observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Card, modalService, TextInput, toastService, toastStyle } from "@vaettyr/boltcave-client-core";

type ChangePasswordProps = {
  authstore?: AuthStore,
  isModal?: boolean,
  modalservice?: modalService,
  toastservice?: toastService
}

const modalName = 'change-password';

export default inject( 'authstore', 'modalservice', 'toastservice' ) ( observer (
  ({ authstore, isModal = true, modalservice, toastservice }: ChangePasswordProps ) => {
    const { busy } = authstore ?? {};

    const initialValues = {
      oldPassword: "",
      confirmPassword: "",
      password: ""
    };

    const changePassword = ({oldPassword, password}: {oldPassword: string, confirmPassword: string, password: string}) => {
      authstore?.ChangePassword({ oldPassword, password, reset: false })
        .then(() => {
          toastservice?.show({
            message: "Password successfully changed",
            dismissable: true,
            style: toastStyle.success,
            lifespan: 2
          });
          modalservice?.hide(modalName);
        })
        .catch(() => {
          toastservice?.show({
            message: "There was an error changing your password",
            dismissable: true,
            style: toastStyle.danger,
            lifespan: 5
          })
        });
    }

    const onCancel = () => modalservice?.hide(modalName);

    return (
      <Formik initialValues={initialValues} onSubmit={changePassword} validateOnChange={true} validateOnMount={true} isInitialValid={false}>
        {({submitForm, dirty, isValid, values}) => {

          const validatePassword = ( value?: string ) => {
            if(value && value.length > 16) return "Password must be no longer than 16 characters";
          }

          const validateVerifyPassword = (value?: string, allValues?: Record<string, any>): undefined|string => {
            const { password } = allValues ?? {};
            if(!!password && !!value && password !== value) return "Passwords do not match";
            return;
          }

          const formIsValid = isValid && dirty && Object.keys(values).reduce((allValid, key) => allValid && !!(values as Record<string, any>)[key], true);

          const renderForm = () => (
            <Form className="change-password-form">
              <TextInput name="oldPassword" label="Current Password" password required />
              <TextInput name="password" label="New Password" password required validate={validatePassword} />
              <TextInput name="confirmPassword" label="Re-enter New Password" password required validate={validateVerifyPassword} />
              {!isModal && <button className="button is-primary" role="button" disabled={ busy || !dirty || !formIsValid } onClick={submitForm}>Save</button>}
            </Form>
          );

          const actions = (
            <>
              <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
              <button className="card-footer-item button is-primary" role="button" disabled={ busy || !dirty || !formIsValid } onClick={submitForm}>Save</button>
            </>
          );

          return isModal ?
            <Card header="Change Password" footer={actions}>
              { renderForm() }
            </Card>:
            renderForm();
        }}
      </Formik>
    );
  }
));