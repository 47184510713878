import React, { useEffect } from 'react';
import {inject, observer} from 'mobx-react';
import AppStore, {appType} from '../stores/appStore';
import { modalService, toastService } from '@vaettyr/boltcave-client-core';
import EditApp from './EditApp';
import RoleStore from '../stores/roleStore';
import { HandleSecureError } from '../helpers/authHelpers';
import AuthStore from '../stores/authStore';

type AppsProps = {
    appstore?: AppStore,
    rolestore?: RoleStore,
    modalservice?: modalService,
    toastservice?: toastService,
    authstore?:AuthStore
}

export default inject('appstore', 'modalservice', 'rolestore', 'toastservice', 'authstore' ) ( observer(
    function Apps({ appstore, modalservice, rolestore, toastservice, authstore }:AppsProps) {

        useEffect(() => {
            rolestore?.fetchPermissions();
        }, []);

        const { apps = [] } = appstore ?? {};

        const showModal = (app:appType) => {
            return () => {
                modalservice?.show({
                    body:<EditApp app={app}/>,
                    key:"edit-app"
                });
            }
        }

        const removeApp = (app:appType) => {
            return (event:React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                appstore?.DeleteApp(app)
                    .catch((err) => {
                        HandleSecureError(err, toastservice, authstore);
                    });
            }
        }

        return (
            <div className="apps-table">
                { apps.slice().sort((a,b) => (a.SortOrder ?? 0) - (b.SortOrder ?? 0)).map( app => (
                    <article className={`media app`} onClick={showModal(app)} key={`app-${app.id}`}>
                        {app.Icon && (<figure className="media-left">
                            <i className={`fas fa-solid fa-${app.Icon}`}/>
                        </figure>)}
                        <div className="media-content">
                            <div className="content">
                                <p>
                                    <strong>{app.Display ?? app.Service}</strong>
                                    <small>{app.Service ?? app.Url}</small>
                                    <small>{app.SortOrder}</small>
                                    {app.Enabled && (<i className="fas fa-solid fa-square-check"/>)}
                                    {!app.Service && (<button className="delete" onClick={removeApp(app)}></button>)}
                                </p>
                            </div>
                        </div>
                    </article>
                ) ) }
                <button type="button" className="button is-primary" onClick={showModal({})}>New App</button>
            </div>
        )
    }
));