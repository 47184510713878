import {inject, observer} from 'mobx-react';
import {Formik} from 'formik';
import {Card, TextInput, NumberInput, Checkbox, storeHelpers, modalService, toastService} from '@vaettyr/boltcave-client-core';
import AppStore, { appType } from '../stores/appStore';
import RoleStore from '../stores/roleStore';
import PermissionSet from '../form/PermissionSet';
import { HandleSecureError } from '../helpers/authHelpers';
import AuthStore from '../stores/authStore';

type EditAppProps = {
    app:appType,
    appstore?:AppStore,
    rolestore?:RoleStore,
    modalservice?:modalService,
    toastservice?:toastService,
    authstore?:AuthStore
}

type formValues = {
    id?: number,
    Service?: string,
    Url?: string,
    Display?: string,
    Claims?: {Service?:string, Key?:string, Value?:string|number|boolean}[][],
    Icon?: string,
    SortOrder?: number,
    Enabled: boolean
}

export default inject('rolestore', 'appstore', 'modalservice', 'toastservice', 'authstore') ( observer(
    function EditApp({app, appstore, modalservice, toastservice, authstore, rolestore}:EditAppProps) {

        const { permissions = [] } = rolestore ?? {};

        const mappedClaims = Array.isArray(app.Claims) ?
            app.Claims.map(c => {
                return Array.isArray(c) ?
                    c.map(s => ({Service:s.key.split('.')[1], Key:s.key.split('.')[2], Value:s.value}) ) :
                    [{Service:c.key.split('.')[1], Key:c.key.split('.')[2], Value:c.value}];
            }) : (app.Claims ?
                [[{Service:app.Claims.key.split('.')[1], Key:app.Claims.key.split('.')[2], Value:app.Claims.value}]]
                : []);

        const initialValues = {
            id:app.id,
            Service:app.Service,
            Display:app.Display ?? "",
            Claims:mappedClaims,
            Url:app.Url ?? storeHelpers.GetEndpoint(app.Service as string),
            Icon: app.Icon ?? "",
            SortOrder: app.SortOrder ?? undefined,
            Enabled: app.Enabled ?? false
        };

        const saveApp = async(values:formValues) => {
            const condensedClaims = values.Claims?.map(claim => {
                const condensed = claim.map(c => {
                    const permission = permissions.find(p => p.Service === c.Service && p._key === c.Key);
                    const key = permission ? `${permission.Type}.${permission.Service}.${permission._key}`: "";
                    return {key, value:c.Value ?? false};
                });
                return condensed.length === 1 ? condensed[0] : condensed;
            });

            const updated = {
                id: values.id,
                Service:values.Service,
                Url: values.Service ? undefined : values.Url,
                Display: values.Display,
                Icon: values.Icon,
                SortOrder: values.SortOrder,
                Enabled: values.Enabled,
                Claims: (condensedClaims?.length === 1 && !Array.isArray(condensedClaims[0])) ?
                    (condensedClaims[0].key ? condensedClaims[0] : undefined ) :
                    ( condensedClaims?.length === 0 ? undefined : condensedClaims )
            }

            appstore?.SaveApp(updated)
                .then(() => {
                    modalservice?.hide('edit-app');
                })
                .catch( err => {
                    HandleSecureError(err, toastservice, authstore);
                });
        }

        const cancel = () => {
            modalservice?.hide('edit-app');
        }

        return (
            <Formik initialValues={initialValues} onSubmit={saveApp}>
                {({
                    handleSubmit,
                    isValid,
                    values,
                    dirty
                }) => {
                const actions = (
                    <>
                        <button className="card-footer-item button is-primary" type="button" onClick={()=>{handleSubmit()}} disabled={!isValid || !dirty}>Save</button>
                        <button className="card-footer-item button" onClick={cancel}>Cancel</button>
                    </>
                );
                return (
                    <Card header="Edit App" footer={actions}>
                        <form onSubmit={handleSubmit} className="app-form">
                            <TextInput name="Display" label="Display Name"/>
                            <TextInput name="Url" label="Url" disabled={!!app.Service}/>
                            <NumberInput name="SortOrder" label="Sort Order"/>
                            <div className="icon-select">
                                <TextInput name="Icon" label="Icon"/>
                                {values.Icon && (<i className={`fas fa-solid fa-${values.Icon}`}/>)}
                            </div>
                            <Checkbox name="Enabled" label="Enabled"/>
                            <PermissionSet name="Claims" label="Claims" permissions={permissions}/>
                        </form>
                    </Card>
                )}}
            </Formik>
        );
    }
));