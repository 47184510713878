import { inject, observer } from 'mobx-react';
import React, { useState, ReactNode, Children, cloneElement, isValidElement, ReactElement } from 'react';
import authStore from '../stores/authStore';
import { modalService, toastService, storeHelpers, toastStyle } from '@vaettyr/boltcave-client-core';
import Login from './Login';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import AppStore from '../stores/appStore';
import useGoogleLogin from '../hooks/useGoogleLogin';

type AuthenticatorProps = {
    authstore?:authStore,
    appstore?:AppStore,
    modalservice?:modalService,
    toastservice?:toastService,
    oneTap?:boolean,
    promptOnly?:boolean,
    hoverSignOut?:boolean,
    editProfile?: React.FunctionComponent,
    children?: ReactNode | ReactNode[],
    trigger?: ReactElement<{onClick?:React.MouseEventHandler, className?: string}>,
    onClick?: ((e?:React.MouseEvent) => void)
}

export default inject('authstore', 'modalservice', 'toastservice', 'appstore') ( observer (
    function Authenticator({authstore, appstore, modalservice, toastservice, onClick, oneTap = false, promptOnly = false, hoverSignOut = false, editProfile, children, trigger}:AuthenticatorProps) {
        const { authenticated, user, session, types = [] } = authstore ?? {};
        const defaultGoogle = types.length === 1 && types[0] === 'google';
        const googleEnabled = types.includes("google");

        const onGoogleLogin = async ({credential}:any):Promise<void> => {
            authstore?.Authenticate("google", { token: credential })
                .then(({apps}) => {
                    if(!promptOnly && !defaultGoogle) {
                        modalservice?.hide('login');
                    }
                    appstore?.SetApps(apps);
                })
                .catch(err => {
                    storeHelpers.HandleError(err, toastservice);
                });
        }

        const [menuActive, setMenuActive] = useState<boolean>(false);
        const isReady = useGoogleLogin({ callback: onGoogleLogin, oneTap, enabled: googleEnabled });

        const showSignIn = (e:React.MouseEvent) => {
            modalservice?.show({
                body: <Login/>,
                options:{className:"login"},
                key:"login"
            });
            setMenuActive(false);
            if(onClick) {
                onClick(e);
            }
        }

        const signOut = (e:React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            authstore?.SignOut()
            .then(() => {
                if(!hoverSignOut) {
                    setMenuActive(false);
                }
                if(onClick) {
                    onClick(e);
                }
                toastservice?.show({
                    message: "Logged Out",
                    style: toastStyle.success,
                    dismissable: true,
                    lifespan: 2
                });
            })
            .catch(() => {
                toastservice?.show({
                    message: 'There was an error logging you out.',
                    style: toastStyle.warning,
                    dismissable: true,
                    lifespan: 5
                  });
            });
        }

        const showEditProfile = (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            const ProfileComponent = editProfile ?? EditProfile;
            modalservice?.show({
                body: <ProfileComponent isModal />,
                key: "edit-profile",
                options: { className: "edit-profile" }
            });
            setMenuActive(false);
            if(onClick) {
                onClick(e);
            }
        }

        const showChangePassword = (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            modalservice?.show({
                body: <ChangePassword />,
                key: 'change-password',
                options: { className: 'change-password'}
            });
            setMenuActive(false);
            if(onClick) {
                onClick(e);
            }
        }

        const toggleMenu = () => {
            if(!hoverSignOut) {
                setMenuActive(!menuActive);
            }
        }

        if(!isReady) { return null; }

        if(promptOnly) {
            return <Login isModal={false}/>;
        }

        const menuItems = !!children ? Children.map(children, (child, key) => {
            if (isValidElement(child)) {
                const childProps = { ...child.props, key };
                if(typeof(child) === 'object') {
                    childProps.onClick = (e: React.MouseEvent) => {
                        if(child.props.onClick) child.props.onClick(e);
                        setMenuActive(false);
                    };
                }
                return <div className="navbar-item" key={key}>{cloneElement(child, childProps)}</div>;
            }
            return child;
        }) : null;

        const triggerComp = !!trigger ? ( isValidElement(trigger) ? cloneElement(trigger, { className: 'dropdown-trigger', onClick: toggleMenu }) : trigger) : undefined;

        return (
            <div className={`navbar-item authenticator has-dropdown${menuActive?" is-active":""}${hoverSignOut?" is-hoverable":""}`}>
                { !authenticated && defaultGoogle && <Login isModal={false}/> }
                { !authenticated && !defaultGoogle && <button className="button is-primary" onClick={showSignIn}>Sign In</button> }
                { authenticated && (
                    <>
                        { !!trigger && triggerComp }
                        { !trigger && (<figure className="image is-32x32 dropdown-trigger" onClick={toggleMenu}>
                            {!user?.Avatar && (<i className="fas fa-solid fa-user"/>)}
                            {user?.Avatar && (<img className="is-rounded" referrerPolicy="no-referrer" src={user?.Avatar}/>)}
                        </figure> ) }
                        <div className={`navbar-dropdown is-boxed is-right user-options`}>
                            <div className="navbar-item" onClick={showEditProfile}>
                                Edit Profile
                            </div>
                            {session?.Type?.toLowerCase() === 'standard' && (<div className="navbar-item" onClick={showChangePassword}>
                                Change Password
                            </div>)}
                            { menuItems }
                            <h3 className="navbar-divider"/>
                            <div className="navbar-item" onClick={signOut}>
                                Sign Out
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
) )

