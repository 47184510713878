import { Formik } from 'formik';
import { TextInput, modalService, toastService, toastStyle } from '@vaettyr/boltcave-client-core';
import AuthStore from '../stores/authStore';
import { inject, observer } from 'mobx-react';
import { validateEmail } from '../helpers/authHelpers';

type formValues = {
  firstName?: string,
  lastName?: string,
  email: string,
  displayName?: string,
  password: string,
  verifyPassword: string
}

type registerProps = {
  authstore?: AuthStore,
  modalservice?: modalService,
  toastservice?: toastService,
  isModal?: boolean,
  chooseMode: ((mode:string|null) => void)
}

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  username: "",
  displayName: "",
  password: "",
  verifyPassword: ""
}

export default inject('authstore', 'modalservice', 'toastservice') (observer (({ authstore, isModal, modalservice, toastservice, chooseMode }: registerProps) => {

  const { busy = false } = authstore ?? {};

  const register = ({ email, firstName, lastName, displayName, password }: formValues) => {
    const params = {
      email,
      display: displayName,
      firstname: firstName,
      lastname: lastName,
      passkey: password
    };
    authstore?.Register(params, 'standard')
    .then(() => {
      toastservice?.show({
        message: "Account successfully created",
        style: toastStyle.success,
        dismissable: true,
        lifespan: 2
      });
      if(isModal) {
        modalservice?.hide("login");
      }
    })
    .catch(({message, types} ) => {
      toastservice?.show({
        message,
        style: toastStyle.danger,
        dismissable: true,
        lifespan: 5
      });
      if(types && types.length > 0) {
        chooseMode(types[0]);
      }
    });
  }

  return (
    <Formik initialValues={defaultValues} onSubmit={register} validateOnChange={false} validateOnBlur={false}>
        {({
            handleSubmit,
            isValid,
            dirty
        }) => {

        const validatePassword = ( value?: string ) => {
          if(value && value.length > 16) return "Password must be no longer than 16 characters";
        }

        const validateVerifyPassword = (value?: string, values?: Record<string, any>): undefined|string => {
          const { password } = values ?? {};
          if(!!password && !!value && password !== value) return "Passwords do not match";
          return;
        }

        return(
          <form onSubmit={handleSubmit} className="role-form">
              <TextInput name="email" label="Email" validate={validateEmail}/>
              <TextInput name="password" label="Password" password required validate={validatePassword}/>
              <TextInput name="verifyPassword" label="Re-enter Password" password required validate={validateVerifyPassword} />
              <TextInput name="displayName" label="Display Name" />
              <TextInput name="firstName" label="First Name" />
              <TextInput name="lastName" label="Last Name" />
              <button className="button" onClick={() => chooseMode(null)}>Sign In a Different Way</button>
              <button className="button is-primary" disabled={!isValid||busy||!dirty} onClick={() => handleSubmit()}>Register</button>
          </form>
        )}}
    </Formik>
  )
}));