import { useEffect } from 'react';
import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import UserStore from '../stores/userStore';
import RoleStore from '../stores/roleStore';
import { Card, modalService, TextInput, Select } from '@vaettyr/boltcave-client-core';

type ApiUserProps = {
  userstore?: UserStore,
  modalservice?: modalService,
  rolestore?: RoleStore
}

const modalName = 'api-user';

export default inject('userstore', 'modalservice', 'rolestore') ( observer (
({ userstore, modalservice, rolestore }: ApiUserProps) => {

  const { roles = [] } = rolestore ?? {};

  const initialValues = {
    name: '',
    type: '',
    role: 0
  }

  useEffect(() => {
    rolestore?.fetch();
  }, []);

  const saveUser = (values: { name: string, type: string, role: number }) => {
    userstore?.createApiUser(values)
      .then(() => modalservice?.hide(modalName));
  }

  const onCancel = () => modalservice?.hide(modalName);



  return (
    <Formik initialValues={initialValues} onSubmit={saveUser}>
        {({ handleSubmit, isValid }) => {
            const actions = (
              <>
                <button className="card-footer-item button is-primary" onClick={()=>{handleSubmit()}} disabled={!isValid}>Save</button>
                <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
              </>
            )
            return (
                <Card header="Create API User" footer={actions}>
                    <TextInput name='name' label='API Username' required />
                    <TextInput name='type' label='API User Type' required />
                    <Select name="role" label="role" required options={['', ...roles.map((r) => ({label: r.name, value: r.id}))]} />
                </Card>
            )
        }}
    </Formik>
  )
}));