import { storeHelpers, toastService } from "@vaettyr/boltcave-client-core";
import axios from "axios";
import AuthStore from "../stores/authStore";

export function HandleSecureError(err:any, toastservice?:toastService, authstore?: AuthStore) {
    if(axios.isAxiosError(err)) {
        const {data, status} = err.response as {data:any, status:number};
        if(status === 401) {
            if(!authstore?.check()) {
                return storeHelpers.HandleError("You session has expired. Please log in again.", toastservice);
            }
        }
        return storeHelpers.HandleError(data, toastservice);
    }
    return storeHelpers.HandleError(err, toastservice);
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const validateEmail = (email?: string): string|undefined => {
    return emailRegex.test(email ?? '') ? undefined : "Please enter a valid email address";
}