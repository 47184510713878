import { ReactNode, Children, cloneElement, isValidElement } from "react";
import { BaseApp } from "@vaettyr/boltcave-client-core";
import userStore from "./stores/userStore";
import roleStore from "./stores/roleStore";
import authStore from "./stores/authStore";
import appStore from "./stores/appStore";

type SecureAppProps = {
    children: ReactNode | ReactNode[],
    name?:string,
    stores?:{[key:string]:object}
}

const authstore = new authStore();
const rolestore = new roleStore();
const userstore = new userStore();
const appstore = new appStore();

export default function SecureApp({children, name="App", stores}:SecureAppProps) {

    const allStores = {
        ...stores,
        authstore,
        rolestore,
        userstore,
        appstore
    };

    const childrenWithKeys = Children.map(children, (child, key) => {
        if (isValidElement(child)) {
          return cloneElement(child, { key });
        }
        return child;
    });

    return (
        <BaseApp name={name} stores={allStores}>
            {childrenWithKeys}
        </BaseApp>
    );
}