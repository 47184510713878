import { inject, observer } from 'mobx-react';
import {Formik} from 'formik';
import {Card, TextInput, Checkbox, Select} from '@vaettyr/boltcave-client-core';
import {Role, Permission, App} from '../type';
import Claims from '../form/Claims';
import RoleStore from '../stores/roleStore';
import AppStore from '../stores/appStore';

type EditRoleProps = {
    appstore?: AppStore,
    role?:Role,
    rolestore?: RoleStore
    permissions?:Record<string, Record<string, Permission[]>>,
    onSave:((values:{[key:string]:any}) => any ),
    onCancel:(() => void)
}

export default inject('appstore', 'rolestore') ( observer (
({appstore, role, permissions, rolestore, onSave, onCancel}:EditRoleProps) => {

    const { apps = [] } = appstore ?? {};
    const mappedClaims = role?.claims?.filter(c => !!c).reduce((acc, claim) => {
        return { ...acc, [claim.key.replace(/\./g, '-')]:claim.value }
    }, {}) ?? {};

    const appOptions = apps.map((a) => ({label: a.Display ?? a.Service ?? a.Url ?? '', value: a.id ?? 0}));

    const initialValues = {
        id: role?.id,
        name: role?.name ?? '',
        default: role?.default ?? false,
        app: (role?.app as App)?.id ?? '',
        ...mappedClaims
    }

    const validateName = (value?: string): string|undefined => {
        const { roles = [] } = rolestore ?? {};
        if(roles.some((r) => r.name === value && r.id !== role?.id)) {
            return 'Name must be unique';
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSave}>
            {({
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isValid,
                values,
                dirty
            }) => {
            const showDefault = !!values.app;
            const actions = (
                <>
                    <button className="card-footer-item button is-primary" type="button" onClick={()=>{handleSubmit()}} disabled={!isValid || !values.name || !dirty}>Save</button>
                    <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                </>
            );
            return(
                <Card header="Edit Role" footer={actions}>
                    <form onSubmit={handleSubmit} className="role-form">
                        <div className="form-control-row">
                            <TextInput name="name" label="Name" required validate={validateName}/>
                            <Select name="app" label="App" options={['', ...appOptions]} />
                            { showDefault && <Checkbox name="default" label="Default" /> }
                        </div>
                        <Claims permissions={permissions} context="role" setValue={setFieldValue} setTouched={setFieldTouched} />
                    </form>
                </Card>
            )}}
        </Formik>
    );
} ));