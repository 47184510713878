import { Permission } from "../type";
import Claim from "./Claim";
import { Collapsible } from '@vaettyr/boltcave-client-core'

type ClaimsProps = {
    permissions?: Record<string, Record<string, Permission[]>>,
    context?: string,
    setValue?:(field: string, value: any, shouldValidate?: boolean) => void,
    setTouched?:(field: string, value: boolean, shouldValidate?:boolean) => void
}

type AppProps = {
    context?: string,
    name:string,
    services: Record<string, Permission[]>,
    setValue?:(field: string, value: any, shouldValidate?: boolean) => void,
    setTouched?:(field: string, value: boolean, shouldValidate?:boolean) => void
}

type ServiceProps = {
    context?: string,
    name:string,
    servicePermissions: Permission[],
    setValue?:(field: string, value: any, shouldValidate?: boolean) => void,
    setTouched?:(field: string, value: boolean, shouldValidate?:boolean) => void
}

const App = ({name, services, ...rest}:AppProps) => {
    const renderedServices = Object.keys(services).map((service, index) => {
        const servicePermissions = services[service];
        return <Service key={`app-${name}-${index}`} name={service} servicePermissions={servicePermissions} {...rest} />
    });
    return name === 'undefined' ? (
        <>
        { renderedServices }
        </>
    ) : (
        <Collapsible header={name}>
            <div>
            { renderedServices }
            </div>
        </Collapsible>
    )
}

const Service = ({context, name, servicePermissions, setValue, setTouched}:ServiceProps) => {
    return (
        <Collapsible header={name}>
            <div>
                {servicePermissions.map((permission, index) => {
                    return (
                        <Claim
                            permission={permission}
                            context={context}
                            setValue={setValue}
                            setTouched={setTouched}
                            key={`permission-${permission.id}-${index}`}
                        />
                    )
                })}
            </div>
        </Collapsible>
    )
}

export default function Claims(props: ClaimsProps) {
    const { permissions = {}, context, ...rest } = props;

    return (
        <div className={`permission-claims-list ${!!context ? context : ''}`}>
            {Object.keys(permissions)
                .map( ( app, index ) => {
                    const services = permissions[app];
                    return <App key={`app-${app}-${index}`} services={services} name={app} {...rest} />; // renderApp(app, services, index);
                }
            )}
        </div>
    );
}