import { inject, observer } from 'mobx-react';
import AuthStore from '../stores/authStore';
import { Formik } from 'formik';
import { toastService, modalService, toastStyle, TextInput } from '@vaettyr/boltcave-client-core';

type anonymousLoginProps = {
  authstore?: AuthStore,
  toastservice?: toastService,
  modalservice?: modalService,
  isModal?: boolean,
  chooseMode: (() => void)
}

export default inject('authstore', 'modalservice', 'toastservice') ( observer (
({ authstore, toastservice, modalservice, isModal, chooseMode }: anonymousLoginProps) => {

  const { busy = false } = authstore ?? {};

  const login = ({ displayName }: { displayName: string, }) => {
    authstore?.Register({ display: displayName }, "anonymous")
    .then(() => {
      toastservice?.show({
        message: "Logged In",
        style: toastStyle.success,
        dismissable: true,
        lifespan: 2
      });
      if(isModal) {
        modalservice?.hide('login');
      }
    }).catch(() => {
      toastservice?.show({
        message: 'There was an error logging you in.',
        style: toastStyle.warning,
        dismissable: true,
        lifespan: 5
      });
    });
  }

  return (
    <Formik initialValues={{ displayName: "" }} onSubmit={login}>
        {({
            handleSubmit,
            isValid,
            dirty
        }) => {

        return(
          <form onSubmit={handleSubmit} className="role-form">
              <TextInput name="displayName" label="Display Name" required />
              <button className="button" onClick={chooseMode}>Log In a Different Way</button>
              <button className="button is-primary" disabled={!isValid||busy||!dirty} onClick={() => handleSubmit()}>Sign In</button>
          </form>
        )}}
    </Formik>
  );
} ));