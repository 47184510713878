import { Formik } from 'formik';
import {useState} from 'react';
import { inject, observer } from 'mobx-react';
import { Card, modalService, Select, toastService } from '@vaettyr/boltcave-client-core';
import userStore from '../stores/userStore';
import roleStore from '../stores/roleStore';
import AuthStore from '../stores/authStore';
import { Identity, User } from '../type';
import Claims from '../form/Claims';
import {mapClaimsToValues} from '../helpers/claimHelpers';
import {Collapsible} from '@vaettyr/boltcave-client-core';
import { HandleSecureError } from '../helpers/authHelpers';
import Avatar from '../components/Avatar';

type EditUserProps = {
    authstore?: AuthStore,
    userstore?: userStore,
    rolestore?: roleStore,
    toastservice?:toastService,
    modalservice?:modalService,
    user:User
}

export default inject('authstore', 'rolestore', 'userstore','modalservice', 'toastservice') ( observer (
    function EditUser({authstore, userstore, rolestore, user, modalservice, toastservice}:EditUserProps) {
        const { roles = [], permissions = [], byApp } = rolestore ?? {};

        const [initialValues, setinitialValues] = useState({Role:user.Role?.id, ...mapClaimsToValues(user.claims??[])});
        const [roleHasChanged, setRoleHasChanged] = useState<boolean>(false);
        const [newPassword, setNewPassword] = useState<string|null>(null);

        const roleOptions = ['', ...roles.map(role => ({label:role.name, value: role.id}))];

        function saveUser(values:{}) {

            const { source } = (values as {source:{[key:string]:boolean}});
            const inherited = Object.keys(source).filter(key => source[key]);

            const parsedClaims = Object.keys(values)
                .filter(key => (/^[^\-]+\-[^\-]+\-[^\-]+$/).test(key) && !inherited.includes(key) && !!(values as {[key:string]:any})[key])
                .map(key => {
                    const name = key.replaceAll('-', '.');
                    const id = permissions.find(p => `${p.Type}.${p.Service}.${p._key}` === name )?.id;
                    const deleted = (values as {remove:string[]}).remove?.includes(key);
                    const value = (values as {[index:string]:any})[key];
                    return {permissionid:id, key:name, deleted, value: Array.isArray(value) ? value.join(',') : value}
                });

            const nonClaims = Object.keys(values)
                .filter( key => !(/^[^\-]+\-[^\-]+\-[^\-]+$/).test(key) && key !== 'source' && key !== 'remove')
                .reduce((acc, key) => ({...acc, [key]:(values as {[key:string]:any})[key]}), {});

            userstore?.saveUser({...user, ...nonClaims, claims:parsedClaims})
                .then( () => {
                    modalservice?.hide("user");
                })
                .catch( err => {
                    HandleSecureError(err, toastservice);
                });
        }

        const resetPassword = () => {
            authstore?.ChangePassword({reset: true, userid: user.id})
            .then((updatedPassword) => {
                setNewPassword(updatedPassword ?? "");
            });
        }

        function onCancel() {
            modalservice?.hide('user');
        }

        const onRoleChange = (onChange: ((field: string, isTouched?: boolean, shouldValidate?: boolean) => void)) => (role:number|string|undefined) => {
            const id = typeof role === 'string' ? parseInt(role, 10) : role;
            if(id) {
                rolestore?.loadRole({id})
                .then((loaded) => {
                    const { claims = [] } = loaded;
                    const newClaims = [
                        ...(user.claims ?? []).filter(claim => !claim.role),
                        ...claims?.filter(claim => !user.claims?.some(c => c.key === claim.key && !c.role))
                    ];
                    setinitialValues({ Role: id, ...mapClaimsToValues(newClaims)});
                    onChange("Role", true);
                    setRoleHasChanged(true);
                })
                .catch(err => {
                    HandleSecureError(err, toastservice);
                });
            }
        }

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={saveUser}>
                {({
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    handleReset,
                    dirty
                }) => {
                    const isDirty = dirty || roleHasChanged;
                    const actions = (
                        <>
                            <button className="card-footer-item button is-primary" onClick={()=>{handleSubmit()}} disabled={!isDirty}>Save</button>
                            <button className="card-footer-item button" disabled={!isDirty} onClick={handleReset}>Reset</button>
                            <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
                        </>
                    );
                    return (
                        <Card header="Edit User" footer={actions}>
                            <div className="user-info">
                                <Avatar image={user.Avatar} size="medium" className="media-left" />
                                <div className="user-details">
                                    <p>
                                        <strong>{user.FirstName} {user.LastName}</strong>
                                        <small>{user.Email}</small>
                                    </p>
                                    <Collapsible header="Identities" className="identities">
                                        <div>
                                            {user.identities?.map((identity:Identity, index:number) => (
                                                <p className="identity" key={`identity-${index}`}>
                                                    <strong>{identity.Type}:</strong> <small>{identity.Value}</small>
                                                    {identity.Type?.toLowerCase() === 'standard' && (
                                                        <>
                                                            {newPassword === null && (<button className="button" disabled={authstore?.busy} onClick={resetPassword}>Reset Password</button>)}
                                                            {newPassword !== null && (<><strong className="updated-password">New Password:</strong> <small>{newPassword}</small></>)}
                                                        </>
                                                    )}
                                                </p>
                                            ))??null}
                                        </div>
                                    </Collapsible>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="user-form">
                                <Select label="Role" name="Role" options={roleOptions} onChange={onRoleChange(setFieldTouched)}/>
                                <h3 className="title is-3">Permissions</h3>
                                <Claims permissions={byApp} context="user" setValue={setFieldValue} setTouched={setFieldTouched}/>
                            </form>
                        </Card>
                    )
                }}
            </Formik>
        );
    }
))