import {inject, observer} from 'mobx-react';
import {useState, useEffect} from 'react';
import { Logo, storeHelpers } from '@vaettyr/boltcave-client-core';
import AuthStore from '../stores/authStore';
import AppStore from '../stores/appStore';

type AppListProps = {
    authstore?: AuthStore,
    appstore?:AppStore,
    height?: number,
    width?: number,
    fill?: string,
    sort?:boolean
}

export default inject('authstore', 'appstore') ( observer (
    function AppList({authstore, appstore, sort, ...rest}:AppListProps) {
        const [active, setActive] = useState(false);
        useEffect(() => {
            appstore?.Initialize();
        }, []);

        const { displayApps = [] } = appstore ?? {};

        const toggleList = () => {
            if(displayApps.length > 1 ) {
                setActive(!active);
            }
        }

        const isActive = (app:{Url:string}) => {
            const target = (app.Url[app.Url.length - 1] === "/") ? app.Url.substring(0, app.Url.length - 1) : app.Url;
            return target === window.location.origin;
        }

        const renderLogo = () => {
            const icon = storeHelpers.GetEnvVar("icon");
            if(icon) {
                switch(typeof(icon)) {
                    case "string":
                        return (
                            <figure className={`image is-72x72${displayApps.length > 1 ? " active":""}`} onClick={toggleList} {...rest} key="logo">
                                <img src={icon}/>
                            </figure>
                        );
                    case "object":
                        const { src = "", size = "92" } = icon;
                        return (
                            <figure className={`image is-${size}x${size}${displayApps.length > 1 ? " active":""}`} onClick={toggleList} {...rest} key="logo">
                                <img src={src}/>
                            </figure>
                    );
                    default:
                        return <Logo onClick={toggleList} className={displayApps.length > 1 ? "active":""} {...rest} key="logo"/>;
                }
            }
        }

        return (
            <>
                {renderLogo()}
                <aside className={`menu app-navigator${active?" active":""}${displayApps.length > 1 ? " enabled":""}`} onClick={toggleList} key="app-list">
                    <p className="menu-label">
                        Apps
                    </p>
                    <ul className="menu-list">
                        {displayApps.map((app, index) => (
                            <li key={`app-${index}`} className={isActive(app) ? "is-active" : ""}>
                                <a href={app.Url}>
                                    {app.Icon && (<i className={`fas fa-solid fa-${app.Icon}`}/>)}
                                    {app.Display}
                                </a>
                            </li>
                        ))}
                    </ul>
                </aside>
            </>
        );
    }
));
