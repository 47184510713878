import { observable, action, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import authStore from './authStore';
import { User, Identity, userQuery } from '../type';
import { storeHelpers } from '@vaettyr/boltcave-client-core';

export default class UserStore
{

    @observable users:User[] = [];
    @observable busy:boolean = false;

    @observable canPage:boolean = false;

    private lastQuery?:userQuery;
    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("AuthService");
    }

    @action page = ():void => {
        if(!this.canPage || !this.lastQuery) return;
        this.fetch({
            ...this.lastQuery,
            paging: {
                size: this.lastQuery.paging?.size ?? 10,
                start: (this.lastQuery.paging?.start ?? 0) + 1
            }
        }, true);
    }

    @action fetch = async (query:userQuery, paging:boolean = false): Promise<User[]> =>
    {
        const queryParams:string[] = [];
        if(query.searchText) queryParams.push(`search=${query.searchText}`);
        if(query.role) queryParams.push(`role=${query.role}`);
        if(query.identity) queryParams.push(`identity=${query.identity}`);
        if(query.id) {
            const ids = Array.isArray(query.id) ? query.id : [query.id];
            queryParams.push(...ids.map((i) => `id[]=${i}`));
        }
        if(query.paging)
        {
            queryParams.push( `pagesize=${query.paging.size}` );
            queryParams.push( `page=${query.paging.start??0}` );
        }
        const queryString = `?${queryParams.join('&')}`;
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.get(`${this.route}api/v1/user/${queryString}`, authStore.GetConfig())
            .then(response => {
                const { data = [] } = response;
                runInAction(() => {
                    this.users = paging ? this.users.concat(data) : data;
                    this.canPage = (query.paging && data.length >= query.paging.size) ?? false;
                    this.lastQuery = query;
                });
                resolve(data);
            }).catch( err => reject(err))
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        })
    }

    @action loadUser = async (user:User): Promise<User> => {
        return new Promise((resolve, reject) => {
            const index = this.users.findIndex(u => u.id === user.id);
            if(index < 0) {
                reject();
            } else if(this.users[index].claims?.length) {
                resolve(this.users[index]);
            } else {
                this.busy = true;
                axios.get(`${this.route}api/v1/user/${user.id}`, authStore.GetConfig())
                    .then( response => {
                        const [updated] = response.data;
                        runInAction(() => { this.users = [...this.users.slice(0, index), updated, ...this.users.slice(index + 1)]; });
                        resolve(updated);
                    })
                    .catch( err => reject(err))
                    .finally(() => {
                        runInAction(() => { this.busy = false; });
                    });
            }
        });
    }

    @action saveUser = async (user:User): Promise<User> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.post(`${this.route}api/v1/user`, user, authStore.GetConfig())
            .then( ({ data:savedUser }:{ data:User } ) => {
                const index = this.users.findIndex(u => u.id === savedUser.id );
                runInAction(() => {
                    if(index >= 0){
                        this.users[index] = savedUser;
                    } else {
                        this.users.push(savedUser);
                    }
                });
                resolve(savedUser);
            })
            .catch(err => {
                if(axios.isAxiosError(err)) {
                    const { response: { data } = {} } = err;
                    reject(data);
                } else {
                    reject(err);
                }
            })
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        });
    }

    @action createApiUser = async (values: {name: string, type: string, role: number}): Promise<User> => {
        this.busy = true;
        return new Promise((resolve, reject) => {
            axios.put(`${this.route}api/v1/user`, values, authStore.GetConfig())
            .then( ({ data:savedUser }:{ data:User } ) => {
                const index = this.users.findIndex(u => u.id === savedUser.id );
                runInAction(() => {
                    if(index >= 0){
                        this.users[index] = savedUser;
                    } else {
                        this.users.push(savedUser);
                    }
                });
                const key = (savedUser.identities?.length ?? 0) > 0 ? (savedUser.identities as Identity[])[0].Value : '';
                resolve(savedUser);
            })
            .catch(err => {
                if(axios.isAxiosError(err)) {
                    const { response: { data } = {} } = err;
                    reject(data);
                } else {
                    reject(err);
                }
            })
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        });
    }
}