import { inject, observer } from 'mobx-react';
import AuthStore from '../stores/authStore';
import { Formik } from 'formik';
import { toastService, modalService, toastStyle, TextInput } from '@vaettyr/boltcave-client-core';

type standardLoginProps = {
  authstore?: AuthStore,
  toastservice?: toastService,
  modalservice?: modalService,
  isModal?: boolean
}

export default inject('authstore', 'modalservice', 'toastservice') ( observer (
({ authstore, toastservice, modalservice, isModal }: standardLoginProps) => {

  const { busy = false } = authstore ?? {};

  const login = ({ email, password }: { email?: string, password?: string }) => {
    authstore?.Authenticate("standard", { email, passkey: password })
    .then(() => {
      toastservice?.show({
        message: "Logged In",
        style: toastStyle.success,
        dismissable: true,
        lifespan: 2
      });
      if(isModal) {
        modalservice?.hide('login');
      }
    }).catch(() => {
      toastservice?.show({
        message: 'There was an error logging you in. Check your username/email and password and try again',
        style: toastStyle.warning,
        dismissable: true,
        lifespan: 5
      });
    });
  }

  return (
    <Formik initialValues={{ email:"", username: "", password:"" }} onSubmit={login}>
        {({
            handleSubmit,
            isValid,
            dirty
        }) => {

        return(
          <form onSubmit={handleSubmit} className="role-form">
              <TextInput name="email" label="Email" required />
              <TextInput name="password" label="Password" required password/>
              <button className="button is-primary" disabled={!isValid||busy||!dirty} onClick={() => handleSubmit()}>Sign In</button>
          </form>
        )}}
    </Formik>
  );
} ));