type AvatarProps = {
  size?: "small" | "medium" | "large",
  className?: string,
  image?: string
}

export default ({ size = "small", className, image }: AvatarProps) => {
  const imageSize = size === 'small' ? '32x32' : (size === 'medium' ? '64x64' : '128x128');
  const iconSize = size === 'small' ? '' : (size === 'medium' ? 'fa-2x' : 'fa-4x');
  return !!image ? (
      <figure className={`image is-${imageSize} ${className ?? ''}`}>
          <img className="is-rounded" src={image}/>
      </figure>
  ) :  (
      <span className={`icon is-${size} ${className ?? ''}`}>
          <i className={`fa-solid fa-circle-user ${iconSize}`}></i>
      </span>
  )
}