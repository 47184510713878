import { observable, action, computed, makeObservable, runInAction } from 'mobx';
import axios from 'axios';
import authStore from './authStore';
import { Role, Permission } from '../type';
import { storeHelpers } from '@vaettyr/boltcave-client-core';

export default class RoleStore
{

    @observable permissions:Permission[] = [];
    @observable roles:Role[] = [];
    @observable busy:boolean = false;
    initialized:{[key:string]:boolean} = {permissions:false, roles:false};

    private route:string;

    constructor()
    {
        makeObservable(this);
        this.route = storeHelpers.GetEndpoint("AuthService");
    }

    @computed get byApp(): Record<string, Record<string, Permission[]>> {
        const apps = this.permissions.map((p) => p.app).filter((a, index, set) => !!a && index === set.findIndex((p) => p?.id === a?.id));
        const mapped = [...apps, null].map((app) => {
            // get all permissions in this app;
            const permissions = this.permissions.filter((p) => (!app && !p.app) || p.app?.id === app?.id);
            const services = permissions.map((p) => p.Service).filter((s, index, set) => index === set.indexOf(s));
            const mappedPermissions = services.map((s) => {
                const servicePermissions = permissions.filter((p) => p.Service === s);
                return [s, servicePermissions];
            });
            return [app?.Display, Object.fromEntries(mappedPermissions)];
        });
        return Object.fromEntries(mapped);
    }

    @action fetchPermissions = () =>
    {
        if(this.initialized.permissions) return;
        this.busy = true;
        axios.get(`/api/v1/role/permissions/`, authStore.GetConfig())
            .then(response => {
                const { data = [] } = response;
                runInAction(() => {
                    this.permissions = data;
                    this.busy = false;
                    this.initialized.permissions = true;
                });
            });
    }

    @action fetch = () =>
    {
        if(this.initialized.roles) return;
        this.busy = true;
        axios.get(`${this.route}api/v1/role`, authStore.GetConfig())
            .then(response => {
                const { data = [] } = response;
                runInAction(() => {
                    this.roles = data;
                    this.busy = false;
                    this.initialized.roles = true;
                });
            });
    }

    @action loadRole = async (role:{id:number}):Promise<Role> => {
        return new Promise((resolve, reject) => {
            const index = this.roles.findIndex(r => r.id === role.id);
            if(index < 0) {
                reject();
            } else if(this.roles[index].claims?.length) {
                resolve(this.roles[index]);
            } else {
                this.busy = true;
                axios.get(`${this.route}api/v1/role/${role.id}`, authStore.GetConfig())
                    .then(response => {
                        const [loaded] = response.data;
                        runInAction(() => {
                            this.roles = [...this.roles.slice(0, index), loaded, ...this.roles.slice(index + 1)];
                        });
                        resolve(loaded);
                    })
                    .catch( err => reject(err))
                    .finally(() => { runInAction(() => { this.busy = false; }); });
            }
        });
    }

    @action saveRole = async (role:Role): Promise<any> =>
    {
        this.busy = true;
        return new Promise((resolve, reject) => {
            const call = role.id ?
                axios.post(`${this.route}api/v1/role`, role, authStore.GetConfig()) :
                axios.put(`${this.route}api/v1/role`, role, authStore.GetConfig());
            call.then( ({ data:savedRole }:{ data:Role } ) => {
                const index = this.roles.findIndex(u => u.id === savedRole.id );
                runInAction(() => {
                    if(index >= 0){
                        this.roles[index] = savedRole;
                    } else {
                        this.roles.push(savedRole);
                    }
                });
                resolve(true);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                runInAction(() => { this.busy = false; });
            });
        });
    }

    @action deleteRole = async (role:Role): Promise<true> => {
        this.busy = true;
        const index = this.roles.findIndex(r => r.id === role.id);
        return new Promise((resolve, reject) => {
            axios.delete(`${this.route}api/v1/role/${role.id}`, authStore.GetConfig())
                .then(() => {
                    runInAction(() => {
                        this.roles = [...this.roles.slice(0, index), ...this.roles.slice(index + 1)];
                    })
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                })
                .finally(() => {
                    runInAction(() => { this.busy = false; });
                });
        });
    }
}