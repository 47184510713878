import AuthStore from "../stores/authStore";
import { User } from "../type";
import { inject, observer } from 'mobx-react';
import { ReactNode } from 'react';
import { Formik, Form } from 'formik';
import { Card, modalService, TextInput, toastService, toastStyle } from "@vaettyr/boltcave-client-core";
import { validateEmail } from '../helpers/authHelpers';

type EditProfileProps = {
  authstore?: AuthStore,
  children?: ReactNode | ReactNode[],
  isModal?: boolean,
  modalservice?: modalService,
  toastservice?: toastService,
  presave?: ((values: User) => Promise<User>),
  postsave?: ((record: User) => Promise<boolean|undefined>) // true means we close it ourself, false means we error, undefined means parent needs to close
}

const modalName = 'edit-profile';

export default inject( 'authstore', 'modalservice', 'toastservice' ) ( observer (
  ({ authstore, children, isModal, modalservice, toastservice, presave, postsave }: EditProfileProps ) => {
    const { user, busy } = authstore ?? {};

    const { Email, Username, FirstName, LastName, Avatar, id } = user ?? {};

    const initialValues = {
      Email: Email ?? "",
      Username: Username ?? "",
      FirstName: FirstName ?? "",
      LastName: LastName ?? "",
      Avatar: Avatar ?? ""
    };

    const onSave = () => {
      toastservice?.show({
        message: "Profile successfully updated",
        dismissable: true,
        style: toastStyle.success,
        lifespan: 2
      });
      modalservice?.hide(modalName);
    };

    const onFail = () => {
      toastservice?.show({
        message: "There was an error updating your profile",
        dismissable: true,
        style: toastStyle.danger,
        lifespan: 5
      });
    }

    const getValues = (values: User): Promise<User> => {
      return !!presave ? presave(values) : new Promise<User>((resolve) => resolve(values));
    }

    const saveProfile = (values: User) => {
      getValues(values)
        .then((userValues) => {
          authstore?.EditProfile({ id, ...userValues })
            .then((updated) => {
              if(postsave) {
                postsave(updated)
                  .then((saved) => {
                    if(saved === undefined) onSave();
                    if(!saved) onFail();
                  })
                  .catch(onFail);
              } else {
                onSave();
              }
            })
            .catch(onFail);
        }).catch(onFail);
    }

    const onCancel = () => modalservice?.hide(modalName);

    return (
      <Formik initialValues={initialValues} onSubmit={saveProfile}>
        {({submitForm, dirty, isValid}) => {

          const renderForm = () => (
            <Form className="profile-form">
              {children}
              <TextInput name="Email" label="Email" validate={validateEmail} required />
              <TextInput name="FirstName" label="First Name" />
              <TextInput name="LastName" label="Last Name" />
              <TextInput name="Username" label="Display Name" />
              {!isModal && <button className="button is-primary" role="button" disabled={ busy || !dirty || !isValid } onClick={submitForm}>Save</button>}
            </Form>
          );

          const actions = (
            <>
              <button className="card-footer-item button" onClick={onCancel}>Cancel</button>
              <button className="card-footer-item button is-primary" role="button" disabled={ busy || !dirty || !isValid } onClick={submitForm}>Save</button>
            </>
          )

          return isModal ? (
            <Card header="Edit Profile" footer={actions} className={modalName}>
              { renderForm() }
            </Card>
          ) : renderForm();
        }}
      </Formik>
    );
  }
));