import { inject, observer } from 'mobx-react';
import { useEffect, useState, ReactNode, Fragment } from 'react';
import { Card, modalService, storeHelpers, toastService, toastStyle } from '@vaettyr/boltcave-client-core';
import AuthStore from '../stores/authStore';
import Register from './Register';
import Anonymous from './Anonymous';
import StandardLogin from './StandardLogin';
import useGoogleLogin, { initializeGoogle } from '../hooks/useGoogleLogin';
import AppStore from '../stores/appStore';

type LoginProps = {
    modalservice?:modalService,
    authstore?: AuthStore,
    appstore?: AppStore,
    toastservice?: toastService,
    isModal?:boolean,
    message?: ReactNode
}

const modalName = "login";

export default inject('modalservice', 'authstore', 'appstore', 'toastservice')( observer(
    function Login({ appstore, toastservice, modalservice, authstore, isModal = true, message }:LoginProps) {

        const { types = [] } = authstore ?? {};
        const googleEnabled = types.includes("google");

        useGoogleLogin({ callback: onGoogleLogin, enabled: googleEnabled });
        const [mode, setMode] = useState<string|null>(null);

        const singleModes: (string|null)[] = ['register', 'anonymous'];

        useEffect(() => {
            if(!singleModes.includes(mode)) {
                initializeGoogle();
            }
        }, [mode]);

        async function onGoogleLogin({credential}:any):Promise<void> {
            authstore?.Authenticate("google", { token: credential })
                .then(({apps}) => {
                    if(isModal) {
                        modalservice?.hide(modalName);
                    }
                    appstore?.SetApps(apps);
                    toastservice?.show({
                        message: "Logged In",
                        style: toastStyle.success,
                        dismissable: true,
                        lifespan: 2
                    });
                })
                .catch(err => {
                    storeHelpers.HandleError(err, toastservice);
                });
        }

        function onCancel() {
            modalservice?.hide(modalName);
        }

        function loginViaDiscord() {
            const discordClientId = storeHelpers.GetEnvVar('dcid');
            const redirect = encodeURIComponent(`${storeHelpers.GetEndpoint("AuthService")}api/v1/auth/authenticate/discord`);
            // const redirect = encodeURIComponent(`https://atlrpg-auth.theboltcave.net/api/v1/auth/authenticate/discord`);
            const queryParams: Record<string, any> = {
                response_type: "code",
                client_id: discordClientId,
                scope: "identify%20email",
                state: window.btoa(JSON.stringify({ redirect: window.location.href })),
                prompt: "none",
                redirect_uri: redirect
            };
            const queryString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
            const uri = `https://discord.com/api/oauth2/authorize?${queryString}`;
            window.location.href = uri;
        }

        const loginAnonymous = () => {
            setMode('anonymous');
        }

        const setRegister = () => {
            setMode('register');
        }

        const chooseMode = (newMode:string|null = null) => {
            setMode(newMode);
        }

        function renderTypes() {
            switch(mode) {
                case 'register':
                    return <Register isModal={isModal} chooseMode={chooseMode} key="login-register"/>;
                case 'anonymous':
                    return <Anonymous isModal={isModal} chooseMode={chooseMode} key="login-anonymous"/>;
                default:
                    return [
                        message ? <Fragment key="login-message">{ message } </Fragment> : null,
                        types.includes("standard") ? <Fragment key="login-standard">
                            <StandardLogin isModal={isModal} key="standard-login"/>
                            { types.length > 1 && <span key="login-separator">Or</span>}
                        </Fragment> : null,
                        ...types.map((type, index) => {
                            switch(type) {
                                case 'google':
                                    return <div key={`${type}-${index}`} id="google-login"></div>;
                                case 'discord':
                                    return <div key={`${type}-${index}`} id='discord-login' onClick={loginViaDiscord}>Sign in with Discord</div>;
                                case 'anonymous':
                                    return <div key={`${type}-${index}`} id='anonymous-login' onClick={loginAnonymous}>Sign in Anonymously</div>
                                default:
                                    return null;
                            }
                        }),
                        types.includes("standard") ? <button key="login-register" className="button is-primary" type="button" onClick={setRegister}>Sign Up</button> : null
                ];
            }
        }

        if(!isModal) {
            return (
                <div className="login-page">
                { renderTypes() }
                </div>
            );
        }

        return (
            <Card header="Sign In" footer={<button className="card-footer-item button" onClick={onCancel}>Cancel</button>}>
                { renderTypes() }
            </Card>
        );
    }
));