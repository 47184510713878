import { Tag } from '@vaettyr/boltcave-client-core';
import { User as UserType } from '../type';

type UserProps = {
    editable?:boolean,
    user: UserType,
    onClick: ((user:UserType) => void)
}

export default (props:UserProps) => {
    const { user, editable = false, onClick } = props;
    const { Username, FirstName, LastName, Role, Email } = user;
    const showModal = () => onClick(user);

    const userName = Username && Username.length > 0 ? Username : `${FirstName ?? ""} ${LastName ?? ""}`;

    return (
        <>
            {user.Avatar && (
                <figure className="image is-32x32" key="user-avatar">
                    <img src={user.Avatar}/>
                </figure>
            )}
            {!user.Avatar && (
                <span className="icon is-large" key="user-avatar">
                    <i className="fa-solid fa-2x fa-circle-user"></i>
                </span>
            )}
            <strong key="user-name">{userName}</strong>
            <small key="user-email">{Email}</small>
            <div key="user-role">
            { user.Role && user.Role.name && (
                <>
                    <i className="fas fa-solid fa-user-tag" key="user-role-icon"/>
                    <span key="user-role-name">{user.Role.name}</span>
                </>
            ) }
            </div>
            <div className="user-identities">
                { !!user.identities && (user.identities.map((i) => <Tag value={i.Type} key={`identity-${i.Type}`}/>)) }
            </div>
            <span className="icon">
                <i className="fas fa-solid fa-file-pen clickable" onClick={showModal}/>
            </span>
            <div className="row-divider"/>
        </>
    );
};