import {Select, Checkbox, TextInput, TagsInput, NumberInput} from '@vaettyr/boltcave-client-core';
import {Permission} from '../type';
import { useFormikContext } from "formik";
import React from "react";

type ClaimProps = {
    permission:Permission,
    context?:string,
    setValue?:(field: string, value: any, shouldValidate?: boolean) => void,
    setTouched?:(field:string, value:boolean, shouldValidate?:boolean) => void
}

export default function Claim(props:ClaimProps) {
    const { permission: { id, Type, Service, _key, _values = [] }, context = "role", setValue, setTouched } = props;
    const values = _values ? ["", ..._values] : [];
    const formattedName = `${Type}-${Service}-${_key}`;
    const formattedLabel = _key.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

    const { values:formValues = {}, touched = {} } = useFormikContext();
    const { source:sources = {}, remove = [] } = (formValues as {[key:string]:any});
    const source = sources[formattedName];
    const isRemoved = remove.includes(formattedName);

    function GetControl() {
        switch(Type) {
            case "BOOLEAN":
                return <Checkbox name={formattedName} label={formattedLabel} disabled={isRemoved}/>
            case "ENUM":
                return <Select name={formattedName} options={values} label={formattedLabel} disabled={isRemoved}/>;
            case "STRING":
                return <TextInput name={formattedName} label={formattedLabel} disabled={isRemoved}/>;
            case "NUMBER":
                return <NumberInput name={formattedName} label={formattedLabel} disabled={isRemoved}/>;
            case "LIST":
                return values.length ?
                <Select name={formattedName} options={values} label={formattedLabel} disabled={isRemoved} multiple/> :
                <TagsInput name={formattedName} label={formattedLabel} disabled={isRemoved}/>;
            default:
                return null;
        }
    }

    function toggleDelete(e:React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if(setValue) {
            if(!isRemoved) {
                setValue('remove', [...remove, formattedName], true);
            } else {
                const index = remove.indexOf(formattedName);
                setValue('remove', [...remove.slice(0, index), ...remove.slice(index + 1)], true);
            }
        }
        if(setTouched) {
            setTouched(formattedName, isRemoved);
        }
    }

    const value = (formValues as {[key:string]:any})[formattedName];

    const hasClaim = value !== null && value !== undefined;

    const canDelete = context === 'role' ? hasClaim : !!value && !source;
    const isDirty = (touched as {[key:string]:boolean})[formattedName];

    const sourceType = !hasClaim ? "" :
        (context === "user" ?
            (source && !isDirty ? " role" : (!!value ? " user" : "")) :
            ` ${context}`);

    return (
        <div className={`claim-field${sourceType} ${isRemoved ? "removed" : ""}`}>
            {GetControl()}
            {canDelete && (<button className="delete" onClick={toggleDelete}></button>)}
        </div>
    );
}